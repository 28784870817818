.flex-row {
  display: flex;
  justify-content: space-between;
}
.ant-card-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  zoom: 1;
}
.new-form-item {
  padding-right: 1em;
  width: 70%;
}
.form-row-item {
  padding-right: 1em;
}
.base-form {
  margin-left: 10vw;
  margin-top: 3vw;
  width: 66%;
}
.form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ant-radio-button-wrapper-checked {
  background: "purple";
  background-color: "purple";
}
.debt-collector-form-container {
  margin: 3vh 10vw 3vh 10vw;
  width: 80%;
}
.form-item-block {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}
.form-item-block > .input-block {
  width: 48%;
  flex: 1;
}
.ant-form-item-children > input,
.ant-select-selection--single,
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child,
.input-height,
.ant-input-number-input {
  height: 35px;
}
.submission-result {
  margin: 10%;
}
.submission-result > div > div {
  width: 50%;
  padding-top: 10%;
}
.penalty-payment-details {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
