@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;700&display=swap);
.logo {
  height: 42px !important;
  /* background: rgba(255, 255, 255, 0.2); */
  margin: 16px;
  width: 80px;
  margin-top: 0;
}
.ant-layout {
  height: -webkit-fill-available;
  max-height: -webkit-fill-available;
  overflow-x: inherit;
}
.ant-layout-sider,
.ant-layout-sider-trigger {
  background-color: rgba(255, 255, 255, 0.979);
  border: solid 2px whitesmoke;
  border-radius: 8px;
  min-width: 250px;
}
.layout-placeholder {
  width: 100%;
}
.loader {
  position: absolute;
  top: 50%;
  left: 60%;
  opacity: inherit;
}
.loader-content {
  z-index: 999;
  position: absolute;
  top: 40%;
  left: 50%;
  opacity: inherit;
}
.header-content {
  display: flex;
  justify-content: space-between;
}
.top-right-menu {
  margin-right: 2em;
  z-index: 100;
  font-size: 16px;
  color: white;
}
.ant-layout-sider-children {
  height: 100%;
  overflow-y: auto;
}
.ant-menu-inline {
  border-right: none;
}
hr {
  border-top: 1px solid whitesmoke;
  border-radius: 2px;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger {
  font-size: 22px;
  line-height: 64px;
  padding: 5px 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.trigger:hover {
  color: #1890ff;
}
.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.header,
.ant-layout-header {
  border-bottom: 1px solid whitesmoke;
  /* box-shadow: 5px 5px 5px lightgrey; */
}
.header {
  background-image: url(/static/media/header_background.06dfbacb.svg);
  background-blend-mode: screen;
}
.logo-container {
  height: 53.5px;
}
.content-wrapper {
  height: 92vh;
  overflow: auto;
}
.ant-menu-item-selected {
  background-color: rgba(211, 211, 211, 0.644) !important;
  color: black !important;
}
.header-content > h3 {
  color: rgb(224, 222, 222);
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: rgb(48, 47, 47);
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: rgb(48, 47, 47);
}
.ant-menu-item .ant-menu-item-active {
  color: rgb(48, 47, 47);
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.no-interact {
  opacity: 0.5;
  pointer-events: none;
}
.no-interact-normal-opacity {
  pointer-events: none;
}
.red-button {
  background-color: #d61818;
  color: white;
}
.red-button:hover,
.red-button:focus {
  color: #fff;
  background-color: #aa0909;
  border-color: #a30202;
}
.purple-button {
  background-color: #43075f;
  color: white;
  margin: 3px;
}
.purple-text {
  color: #43075f;
  cursor: pointer;
  font-weight: bold;
}
.purple-button:hover,
.purple-button:focus {
  color: #fff;
  background-color: #40015e;
  border-color: #40015e;
}
.purple-link {
  color: #43075f;
}
.purple-link:hover,
.purple-link:focus {
  color: #40015e;
}
.ant-table-tbody tr:nth-child(2n) td {
  background-color: #f7f7f7;
}
.ant-table-thead > tr > th {
  background: #f7f7f7;
}
.flex-center-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-page-border {
  margin: 2em 1em 1em 2em;
}
.btn-purple-non-interactive {
  background-color: #43075f;
  border-color: #40015e;
  color: white;
  width: 245px;
  height: 35px;
  pointer-events: none;
}
.btn-purple-non-interactive:hover,
.btn-purple-non-interactive:focus {
  color: white;
  background-color: #40015e;
  border-color: #40015e;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #40015e;
  border-color: #40015e;
}
.ant-tabs-ink-bar {
  background-color: #40015e;
}
.bold {
  font-weight: 700;
}
.full-width {
  width: 100% !important;
}

.form-item {
  padding-left: 1em;
  padding-right: 1em;
  width: 70%;
}
.register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px whitesmoke;
  border-radius: 2px;
}
.register-form-wrapper {
  display: flex;
  height: 100vh !important;
  overflow: hidden;
}
.btn-register {
  width: 19vw;
  height: 40px;
  background-color: #43075f;
}
.btn-register:hover,
.btn-register:focus {
  color: #fff;
  background-color: #40015e;
  border-color: #40015e;
}
.logo-register {
  height: 8em;
  width: 15vw;
  margin-top: 6vh;
}
.sider-background {
  background-image: url(/static/media/login-background-img.14b20f9f.png);
  background-size: cover;
  width: 70%;
  height: 100vh;
}

.sider-background h2 {
  font-family: "Raleway", sans-serif;
  color: white;
  position: absolute;
  top: 80%;
  margin-left: 7%;
  transform: translateY(-100%);
  font-size: 5em;
  text-shadow: 1px 1px 1px #0000003d, 2px 2px 8px #000000b3;
  line-height: 1.2em;
  pointer-events: none;
  font-weight: 700;
}

.sider-background span {
  font-weight: 200;
}

.register-sidebar {
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 40px;
  width: 30%;
  height: 100vh;
}
@media only screen and (max-width: 600px) {
  .register-sidebar {
    width: 100%;
  }
  .sider-background {
    display: none;
  }
  .logo-register {
    width: 50%;
  }
  .btn-register {
    width: 62vw;
  }
}

.notification {
  padding: 40px;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  font-size: 16px;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  font-size: 18px;
}

.form-item {
  padding-left: 1em;
  padding-right: 1em;
  width: 70%;
}
.password-reset-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.password-reset-form-wrapper {
  display: flex;
}
.btn-password-reset {
  width: 19vw;
  height: 40px;
  background-color: #43075f;
}
.btn-password-reset:hover,
.btn-password-reset:focus {
  color: #fff;
  background-color: #40015e;
  border-color: #40015e;
}
.logo-password-reset {
  height: 8em;
  width: 15vw;
  margin-top: 14vh;
}
.password-reset-sider-background {
  background-image: url(/static/media/login-background-img.14b20f9f.png);
  background-size: cover;
  width: 70%;
  height: 100vh;
}
.form-title {
  text-align: center;
  margin-top: 6vh;
  padding-bottom: 3vh;
}

.instruction-text {
  width: 60%;
  text-align: center;
  margin-left: 20%;
}
.password-reset-sidebar {
  overflow-x: hidden;
  overflow-y: auto;
  width: 30%;
  padding-bottom: 40px;
  height: 100vh;
}
@media only screen and (max-width: 600px) {
  .password-reset-sidebar {
    width: 100%;
  }
  .password-reset-sider-background {
    display: none;
  }
  .logo-password-reset {
    width: 50%;
  }
  .btn-password-reset {
    width: 62vw;
  }
}

.information-card {
  /* margin-top: 10px; */
}
.information-block > button {
  background-color: #43075f;
  border-color: #43075f;
  color: white;
  width: 245px;
  height: 35px;
}
.btn-previous-reject {
  width: 120px;
  height: 35px;
  margin-right: 10px;
}
.btn-add-comment {
  background-color: #43075f;
  border-color: #43075f;
  color: #ffffff;
  width: 120px;
  height: 35px;
}
.btn-add-comment:hover,
.information-block > button:focus {
  color: #ffffff;
  background-color: #43075f;
  border-color: #43075f;
}
.information-block > span {
  font-size: 16px;
}
.information-block > span > label {
  font-weight: bold;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.business-info-wrapper > div > label {
  font-size: 16px;
  padding-left: 5px;
}
.ant-card-head-title > span {
  font-weight: 100;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: #43075f;
  border-color: #43075f;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #43075f;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #43075f;
}
.review-steps-sticky {
  position: fixed;
  top: 93px;
}
.card > .ant-card-body {
  margin-left: 15px;
  margin-right: 15px;
}
.ant-badge-status-text {
  font-size: large;
}
.review-sider {
  margin-top: 30px;
  width: 300px;
  display: inline-block;
  vertical-align: top;
}
.review-content {
  width: calc(100% - 300px);
  display: inline-block;
}
.detail-card {
  border: none;
}
.comment-card > .ant-card-body {
  padding: 0;
  height: 55px;
}
.comment-action {
  display: flex;
  justify-content: space-between;
  margin: 15px;
}
.card {
  margin: 20px;
  overflow-y: auto;
}
.row {
  display: flex;
  flex-direction: row;
}
div > label {
  font-size: 16px;
  padding-left: 5px;
}
.review-result-card {
  width: 50%;
  margin-left: 25%;
  background-color: rgb(241, 241, 241);
}
.ant-result-success .ant-result-icon > .anticon {
  color: #43075f;
}
.review-content-section {
  width: 100%;
}
.review-step-content {
  margin: 30px 30px;
}
.information-block-wrapper > .ant-card-body {
  display: flex;
  flex-direction: column;
}
.link-user-menu {
  width: 25%;
}
.link-user-menu > li > .ant-menu-submenu-title {
  top: auto;
  margin-top: auto;
}
.padding-left-2 {
  padding-left: 2rem;
}

.pointer {
  cursor: pointer;
}

.register-form-wrapper {
  display: flex;
  height: 100%;
  overflow: hidden;
}
.sider {
  width: 28%;
  overflow-y: scroll;
}
.content-section {
  width: 72%;
  background-color: white;
  overflow-y: scroll;
}
.ant-steps-item {
  padding-bottom: 20px;
}
.ant-steps-dot {
  width: 100% !important;
  padding-left: 15px;
}
.ant-steps-item-description {
  width: 80%;
}
.ant-steps {
  margin-left: 30px;
}
.sider-title {
  margin: 20px 30px;
}
.sider-title > h1 {
  font-size: 32px;
}
.sider-title > p {
  font-size: 16px;
}
.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: auto;
}
.step-content {
  margin: 60px 60px;
  width: 80;
  overflow-y: auto !important;
}
.btn-registration-type {
  width: 220px;
  height: 40px;
}
.btn-registration-capacity {
  width: 120px;
  height: 40px;
  text-align: center;
}
.registration-capacity,
.business-basic-details {
  padding-top: 40px;
}
.ant-input {
  height: 35px;
}
.input-block {
  width: 48%;
  padding-bottom: 20px;
}
.input-block-language {
  width: 30%;
  padding-bottom: 20px;
}
.input-block > label {
  font-size: medium;
  font-weight: bold;
}
.stepper-btn-container {
  margin-top: 40px;
}
.btn-registration-capacity-selected {
  background-color: #43075f;
  color: white;
}
.btn-registration-capacity-selected:hover,
.btn-registration-capacity-selected:focus {
  color: #fff;
  background-color: #40015e;
  border-color: #40015e;
}
.input-select {
  width: 100% !important;
}
.input-block-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.user-input-block-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.step-title {
  font-size: 22px;
  font-weight: bold;
}
.input-spacer {
  padding-top: 20px;
}
.ant-upload {
  width: 100%;
}
.input-block > hr {
  width: 70%;
  margin-left: 0;
}
.fees-wrapper {
  width: 85%;
  display: flex;
  justify-content: space-between;
}
.input-block-wrapper > .ant-card-body {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
span > label {
  font-weight: bold;
}
.contact-table-card {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  background-color: #fafafa;
}

.fees-table-cell-fee {
  width: 20%;
  border: 1px solid lightgrey;
  height: 55px;
  text-align: center;
}
.fees-table-cell-title {
  width: 80%;
  border: 1px solid lightgrey;
  height: 55px;
  padding-left: 10px;
}
.fees-table {
  width: 100%;
  border: 1px solid lightgrey;
}
.verification-result {
  height: inherit;
  background-color: white;
}
.ant-result-icon > img {
  height: 30%;
  width: 40%;
}
.vertical-center {
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}
.text-red {
  color: #d61818;
}

.red-button {
  background-color: red;
  color: white;
}
.dot {
  height: 7px;
  width: 7px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 1px;
}
.icon-dot {
  height: 25px;
  width: 25px;
  background-color: rgb(45, 48, 230);
  border-radius: 50%;
  display: inline-block;
  color: white;
  text-align: center;
}
.activity-icon-dot {
  height: 25px;
  width: 25px;
  background-color: rgb(82, 196, 26);
  border-radius: 50%;
  display: inline-block;
  color: white;
  text-align: center;
}
.activity-icon-dot > i {
  padding-top: 5px;
}
.table-header {
  width: 25%;
}
.card {
  margin: 20px;
  overflow-y: auto;
}
.form-item-block {
  display: flex;
  justify-content: space-between;
}
.form-item-block > .input-block {
  width: 48%;
}

.ant-result-icon > img {
  height: 30%;
  width: 40%;
}
.message {
  font-size: large;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: rgb(179, 177, 177);
  text-align: justify;
  max-width: 70% !important;
  margin: auto;
}
.title {
  text-align: justify;
  font: 400 32px system-ui;
  margin-top: 1em;
}

.member-profile-header-div {
  background-color: white;
  width: 100%;
  height: 80px;
}
.member-profile-header-div:before,
.member-profile-header-div:after {
  content: "\00a0 ";
}
.member-profile-header-div > h2,
.member-profile-header-div > .ant-breadcrumb {
  margin-left: 15px;
}
.ant-tabs-nav-scroll {
  background-color: white;
}
.flex-row > label {
  margin-top: 10px;
}
.flex-column-record-block {
  display: flex;
  flex-direction: column;
}
.input-block > span {
  font-size: 15px;
}
.input-block > span > label {
  font-weight: bold;
}
.red-button {
  background-color: red;
  color: white;
}

.card-header {
  align-items: flex-start;
}

.card-page-border {
  margin: 0 !important;
}

.header-card {
  height: 20%;
  margin: 20px;
}
.header-card-img {
  width: 20vh;
}
.body-card {
  margin: 20px;
  overflow-y: auto;
}
.ant-avatar.ant-avatar-icon {
  font-size: 25px;
}
.avatar-column {
  display: flex;
  flex-direction: column;
  margin-top: 8em;
  margin-left: 5em;
  align-content: center;
  width: 35%;
}

.avatar-column > span > .ant-upload {
  margin-top: 15px;
  margin-left: 5px;
}
.card-form {
  width: 60%;
}
.header-card > .ant-card-body > div > span {
  font-size: 16px;
  padding-top: 5em;
  padding-left: 2em;
}
.tab-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.btn-link {
  padding-top: 6em;
}
.btn-upload {
  background-color: #43075f;
  color: white;
  width: 150px;
  height: 35px;
  border-radius: 5px;
}
.ant-tabs-tab-active {
  background-color: rgba(211, 211, 211, 0.644);
  color: black;
}

.header-card {
  height: 20%;
  margin: 20px;
}
.header-card-img {
  width: 20vh;
}
.body-card {
  margin: 20px;
  overflow-y: auto;
}
.avatar-column {
  display: flex;
  flex-direction: column;
  margin-top: 8em;
  margin-left: 5em;
  align-content: center;
  width: 35%;
}

.avatar-column > span > .ant-upload {
  margin-top: 15px;
  margin-left: 5px;
}
.card-form {
  width: 60%;
}
.header-card > .ant-card-body > div > span {
  font-size: 16px;
  padding-top: 5em;
  padding-left: 2em;
}
.tab-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.btn-link {
  padding-top: 6em;
}
.btn-upload {
  background-color: #43075f;
  color: white;
  width: 150px;
  height: 35px;
  border-radius: 5px;
}
.btn-default {
  background-color: #43075f;
  border-color: #43075f;
  color: #ffffff;
  width: 150px;
  height: 35px;
  border-radius: 5px;
}
.ant-tabs-tab-active {
  background-color: rgba(211, 211, 211, 0.644);
  color: black;
}

.header-wrapper {
  border-top: 1px solid lightgrey;
  background: rgb(255, 255, 255);
  display: flex;
}
.headers {
  height: 17px;
  font: 400 11px system-ui;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: #435a6f;
  padding-top: 16px;
  border-bottom: 1px solid lightgrey;
  background: rgb(255, 255, 255);
  height: 55px;
  text-align: center;
  border-right: 1px solid lightgrey;
}

.proj-header {
  background: rgb(255, 255, 255);
  padding: 0;
  height: 55px;
  text-align: center;
  display: flex;
  align-items: center;
}

.project-name {
  text-align: center;
  height: 20px;
  font: 400 14px system-ui;
  line-height: 1.25;
  letter-spacing: -0.05px;
  color: var(--dark-slate-blue);
  margin: 10px 5px 0 0;
}
.proj-info {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: rgb(255, 255, 255);
  padding: 10px;
}
.custom-project-filter {
  margin-top: 2vw;
  margin-left: auto;
  margin-right: 2vw;
  width: 25%;
  position: relative;
}
p {
  font: 400 11px system-ui;
  font-size: 13px;
  height: 20px;
  margin: 1em 1em 0 0;
}
.ant-card-meta-avatar {
  margin-top: 2.5em;
}
.ant-card-meta-title {
  font-size: 13px;
}
.card {
  border: 1px solid lightgrey;
  border-radius: 4px;
  flex-basis: 0em;
  padding-bottom: 2.2em;
  margin-bottom: 1.5em;
}
.ant-card {
  width: 100%;
  font-feature-settings: ;
  font-variant: "system-ui";
  margin-bottom: 1em;
}
.ant-comment {
  margin-left: 10px;
  font: 400 13px system-ui;
}
.ant-comment-avatar {
  width: 50px;
}
.ant-avatar-image img {
  margin: 0;
}
.reply {
  /* position: absolute; */
  /* bottom: 0; */
  margin-top: auto;
  align-self: center;
  border-top: 1px solid rgb(230, 230, 230);
  border-bottom: 1px solid lightgrey;
  width: 100%;
  background: rgb(255, 255, 255);

  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.file-upload {
  display: flex;
  padding-top: 15px;
  width: 10%;
}
.file-upload svg {
  width: 50px;
  height: 30px;
  text-align: left;
}
textarea {
  border: none;
  resize: none;
  box-sizing: border-box;
  width: 90%;
  height: 100%;
  font-family: intercom-font, "Helvetica Neue", "Apple Color Emoji", Helvetica,
    Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.33;
  background-color: rgb(255, 255, 255);
  white-space: pre-wrap;
  overflow-wrap: break-word;
  padding: 18px 100px 18px 29px;

  height: 55px;
}
textarea,
input {
  cursor: text;
}
text {
  font: 400 13px system-ui;
}
.ant-comment {
  align-self: flex-start;
}
.concierge-comment {
  align-self: flex-end;
  background-color: rgb(255, 255, 255);
}
.ant-comment-content-detail p {
  background-color: #408ef3;
  border: 1px solid lightgrey;
  border-radius: 10px;
  min-height: 3em;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 25vw;
  font: 400 13px system-ui;
  padding: 12px;
}
.ant-comment-content-author-name {
  font: 400 14px system-ui;
}
.ant-tabs-tab {
  font: 400 12px system-ui;
  padding-top: 20px !important;
}
.projects-container {
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  display: flex;
  height: 92.5vh;
  overflow-y: auto;
  flex-direction: column;
  background: rgb(255, 255, 255);
}
.chat-container {
  background: rgb(255, 255, 255);
  padding: 0;
  height: 92.5vh;
}

.ant-tabs {
  overflow-y: auto;
}

.ant-tabs-ink-bar {
  display: none;
}
.chat {
  display: flex;
  max-height: 83vh;
  flex-direction: column;
  background: rgb(255, 255, 255);
  overflow-y: auto;
  margin: 0;
}
.section-background {
  background: rgb(255, 255, 255);
  height: 92.5vh;
  display: flex;
  flex-direction: column;
  padding-left: 0%;
}

.chatroom-content {
  display: flex;
  overflow-y: hidden;
  background: rgb(255, 255, 255);
}
.active {
  background-color: rgb(209, 238, 250);
}
.ant-table-no-data {
  min-height: 92vh;
}
.placeholder-card {
  margin-top: 2.5em;
  height: 100%;
}
.placeholder-card-spacer {
  margin: 2.5em;
}
.placeholder-table-card {
  margin: 0.6em;
}
.ant-table-wrapper {
  overflow-y: auto;
}
.bu-icon {
  margin-top: 10px;
  height: 6vh;
}
.column-flex {
  display: flex;
  flex-direction: column;
}
.project-card {
  width: 96%;
}
.project-card-title {
  height: 64px;
  padding-right: 15px;
}
.project-header-card {
  margin: 2em 1em 0 2em;
  border: none;
}
.project-status-card {
  margin: 0 1em 1em 2em;
  border: none;
}
.project-team-card {
  display: flex;
  padding-right: 5vw;
}
.avatar {
  height: 50px;
  padding-right: 5;
  padding-top: 4;
}
.team-member-role {
  color: rgba(0, 0, 0, 0.45);
}
.pagination-container {
  text-align: center;
  margin-top: 15px;
}
.filter-holder {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.horizontal-padding-25 {
  padding-left: 25px;
  padding-right: 25px;
}
.filter {
  padding-right: 15px;
  padding-bottom: 5px;
}
.search-filter {
  display: flex;
  margin-left: auto;
}
.ant-spin-nested-loading {
  width: 100%;
}
.change-dates-headings {
  margin: 0.5rem 0;
}
.change-dates-date-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.change-dates-date-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.tasks-filter-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.input-select {
  width: 100%;
}
.card {
  margin: 20px;
  overflow-y: auto;
}
.tasks-header-div {
  background-color: white;
  width: 100%;
  height: 58px;
}
.tasks-header-div:before,
.tasks-header-div:after {
  content: "\00a0 ";
}
.tasks-header-div > h2 {
  margin-left: 15px;
}
.ant-tabs-nav-scroll {
  background-color: white;
}
.btn-new-task {
  background-color: #43075f;
  color: white;
  border-radius: 5px;
}
.filter-input {
  width: 80%;
}

.flex-row > label {
  margin-top: 10px;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

.tasks-filter-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.input-select {
  width: 100%;
}
.card {
  margin: 20px;
  overflow-y: auto;
}
.tasks-header-div {
  background-color: white;
  width: 100%;
  height: 58px;
}
.tasks-header-div:before,
.tasks-header-div:after {
  content: "\00a0 ";
}
.tasks-header-div > h2 {
  margin-left: 15px;
}
.ant-tabs-nav-scroll {
  background-color: white;
}
.btn-new-task {
  background-color: #43075f;
  color: white;
  border-radius: 5px;
}
.filter-input {
  width: 80%;
}

.flex-row > label {
  margin-top: 10px;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

.tasks-filter-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.input-select {
  width: 100%;
}
.card {
  margin: 20px;
  overflow-y: auto;
}
.tasks-header-div {
  background-color: white;
  width: 100%;
  height: 58px;
}
.tasks-header-div:before,
.tasks-header-div:after {
  content: "\00a0 ";
}
.tasks-header-div > h2 {
  margin-left: 15px;
}
.ant-tabs-nav-scroll {
  background-color: white;
}
.btn-new-task {
  background-color: #43075f;
  color: white;
  border-radius: 5px;
}
.filter-input {
  width: 80%;
}

.flex-row > label {
  margin-top: 10px;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

.information-card {
  /* margin-top: 10px; */
}
.information-block > button {
  background-color: #43075f;
  border-color: #43075f;
  color: white;
  width: 245px;
  height: 35px;
}
.btn-previous-reject {
  width: 120px;
  height: 35px;
  margin-right: 10px;
}
.btn-add-comment {
  background-color: #43075f;
  border-color: #43075f;
  color: #ffffff;
  width: 120px;
  height: 35px;
  margin: 10px 0 0 auto;
}
.btn-add-comment:hover,
.information-block > button:focus {
  color: #ffffff;
  background-color: #43075f;
  border-color: #43075f;
}
.information-block > span {
  font-size: 16px;
}
.information-block > span > label {
  font-weight: bold;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.business-info-wrapper > div > label {
  font-size: 16px;
  padding-left: 5px;
}
.ant-card-head-title > span {
  font-weight: 100;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: #43075f;
  border-color: #43075f;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #43075f;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #43075f;
}
.review-steps-sticky {
  position: fixed;
  top: 93px;
}
.card > .ant-card-body {
  margin-left: 15px;
  margin-right: 15px;
}
.ant-badge-status-text {
  font-size: large;
}
.review-sider {
  margin-top: 30px;
  width: 300px;
  display: inline-block;
  vertical-align: top;
}
.review-content {
  width: calc(100% - 300px);
  display: inline-block;
}
.detail-card {
  border: none;
}
.comment-card > .ant-card-body {
  padding: 2.5%;
}
.comment-action {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
.card {
  margin: 20px;
  overflow-y: auto;
}
.row {
  display: flex;
  flex-direction: row;
}
div > label {
  font-size: 16px;
  padding-left: 5px;
}
.review-result-card {
  width: 50%;
  margin-left: 25%;
  background-color: rgb(241, 241, 241);
}
.ant-result-success .ant-result-icon > .anticon {
  color: #43075f;
}
.review-content-section {
  width: 100%;
}
.review-step-content {
  margin: 30px 30px;
}
.information-block-wrapper > .ant-card-body {
  display: flex;
  flex-direction: column;
}
.link-user-menu {
  width: 25%;
}
.link-user-menu > li > .ant-menu-submenu-title {
  top: auto;
  margin-top: auto;
}
.message-box {
  margin: 10px 10px 15px;
}
.message-box p:first-child {
  font-weight: bold;
  margin-bottom: 10px;
}

.form-item {
  padding-left: 1em;
  padding-right: 1em;
  width: 70%;
}
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px whitesmoke;
  border-radius: 2px;
}
.login-form-wrapper {
  display: flex;
  height: 100vh;
}
.btn-account-confirmation,
.btn-signin {
  width: 19vw;
  height: 40px;
  background-color: #43075f;
}
.btn-account-confirmation:hover,
.btn-account-confirmation:focus,
.btn-signin:hover,
.btn-signin:focus {
  color: #fff;
  background-color: #40015e;
  border-color: #40015e;
}
.logo-login {
  height: 8em;
  width: 15vw;
  margin-top: 6vh;
}
.sider-background {
  background-image: url(/static/media/login-background-img.14b20f9f.png);
  background-size: cover;
  width: 70%;
  height: 100vh;
}

.sider-background h2 {
  font-family: "Raleway", sans-serif;
  color: white;
  position: absolute;
  top: 80%;
  margin-left: 7%;
  transform: translateY(-100%);
  font-size: 5em;
  text-shadow: 1px 1px 1px #0000003d, 2px 2px 8px #000000b3;
  line-height: 1.2em;
  pointer-events: none;
  font-weight: 700;
}

.sider-background span {
  font-weight: 200;
}

.login-sidebar {
  overflow-x: hidden;
  overflow-y: auto;
  width: 30%;
  padding-bottom: 40px;
  height: 100vh;
}
.btn-signup {
  margin-top: 20px;
  width: 10vw;
}
@media only screen and (max-width: 600px) {
  .login-sidebar {
    width: 100%;
  }
  .sider-background {
    display: none;
  }
  .logo-login {
    width: 50%;
  }
  .btn-signin {
    width: 62vw;
  }
  .btn-signup {
    width: 30vw;
  }
}

.employees-header-div {
  background-color: white;
  width: 100%;
  height: 58px;
}
.employees-header-div:before,
.employees-header-div:after {
  content: "\00a0 ";
}
.employees-header-div > h2 {
  margin-left: 15px;
}
.ant-tabs-nav-scroll {
  background-color: white;
}
.information-card {
  /* margin-top: 10px; */
}
.information-block > button {
  background-color: #43075f;
  border-color: #43075f;
  color: white;
  width: 245px;
  height: 35px;
}
.btn-previous-reject {
  width: 120px;
  height: 35px;
  margin-right: 10px;
}
.information-block > span {
  font-size: 16px;
}
.information-block > span > label {
  font-weight: bold;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.business-info-wrapper > div > label {
  font-size: 16px;
  padding-left: 5px;
}
.ant-card-head-title > span {
  font-weight: 100;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: #43075f;
  border-color: #43075f;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #43075f;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #43075f;
}
.review-steps-sticky {
  position: fixed;
  top: 93px;
}
.card > .ant-card-body {
  margin-left: 15px;
  margin-right: 15px;
}
.ant-badge-status-text {
  font-size: large;
}
.review-sider {
  margin-top: 30px;
  width: 300px;
  display: inline-block;
  vertical-align: top;
}
.review-content {
  width: calc(100% - 300px);
  display: inline-block;
}
.detail-card {
  border: none;
}
.comment-card > .ant-card-body {
  padding: 0;
  height: 55px;
}
.comment-action {
  display: flex;
  justify-content: space-between;
  margin: 15px;
}
.card {
  margin: 20px;
  overflow-y: auto;
}
.row {
  display: flex;
  flex-direction: row;
}
div > label {
  font-size: 16px;
  padding-left: 5px;
}
.review-result-card {
  width: 50%;
  margin-left: 25%;
  background-color: rgb(241, 241, 241);
}
.ant-result-success .ant-result-icon > .anticon {
  color: #43075f;
}
.review-content-section {
  width: 100%;
}
.review-step-content {
  margin: 30px 30px;
}
.information-block-wrapper > .ant-card-body {
  display: flex;
  flex-direction: column;
}
.link-user-menu {
  width: 25%;
}
.link-user-menu > li > .ant-menu-submenu-title {
  top: auto;
  margin-top: auto;
}

.register-form-wrapper {
  display: flex;
  height: 100%;
  overflow: hidden;
}
.sider {
  width: 35%;
  overflow-y: auto;
}
.content-section {
  width: 65%;
  background-color: white;
  overflow-y: auto;
}
.ant-steps-item {
  padding-bottom: 20px;
}
.ant-steps-dot {
  width: 100% !important;
  padding-left: 15px;
}
.ant-steps-item-description {
  width: 80%;
}
.ant-steps {
  margin-left: 30px;
}
.register-sidebar {
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 40px;
  width: 50%;
}
.sider-title {
  margin: 20px 30px;
}
.sider-title > h1 {
  font-size: 32px;
}
.sider-title > p {
  font-size: 16px;
}
.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: auto;
}
.step-content {
  margin: 60px 60px;
  width: 80;
  overflow-y: auto !important;
}
.btn-registration-type {
  width: 220px;
  height: 40px;
}
.btn-registration-capacity {
  width: 120px;
  height: 40px;
  text-align: center;
}
.registration-capacity,
.business-basic-details {
  padding-top: 40px;
}
.input-block {
  width: 48%;
  padding-bottom: 20px;
}
.input-block-language {
  width: 30%;
  padding-bottom: 20px;
}
.input-block > label {
  font-size: medium;
  font-weight: bold;
}
.stepper-btn-container {
  margin-top: 40px;
}
.btn-registration-capacity-selected {
  background-color: #43075f;
  color: white;
}
.btn-registration-capacity-selected:hover,
.btn-registration-capacity-selected:focus {
  color: #fff;
  background-color: #40015e;
  border-color: #40015e;
}
.input-select {
  width: 100% !important;
}
.input-block-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
}
.user-input-block-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.step-title {
  font-size: 22px;
  font-weight: bold;
}
.input-spacer {
  padding-top: 20px;
}
.ant-upload {
  width: 100%;
}
.input-block > hr {
  width: 70%;
  margin-left: 0;
}
.fees-wrapper {
  width: 85%;
  display: flex;
  justify-content: space-between;
}
.input-block-wrapper > .ant-card-body {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
span > label {
  font-weight: bold;
}
.contact-table-card {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  background-color: #fafafa;
}

.fees-table-cell-fee {
  width: 20%;
  border: 1px solid lightgrey;
  height: 55px;
  text-align: center;
}
.fees-table-cell-title {
  width: 80%;
  border: 1px solid lightgrey;
  height: 55px;
  padding-left: 10px;
}
.fees-table {
  width: 100%;
  border: 1px solid lightgrey;
}
.verification-result {
  height: inherit;
  background-color: white;
}
.ant-result-icon > img {
  height: 30%;
  width: 40%;
}
.vertical-center {
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}

.wrapper {
  margin-top: 15%;
}

.st0 {
  fill: #fff;
}
.st2 {
  fill: #5d89af;
}
.st3 {
  fill: #709abf;
}
.st4,
.st6 {
  fill: #fff;
  stroke: #b3dcdf;
  stroke-miterlimit: 10;
}
.st6 {
  stroke: #5d89af;
  stroke-width: 2;
}
.st7,
.st8,
.st9 {
  stroke: #709abf;
  stroke-miterlimit: 10;
}

.st7 {
  stroke-width: 5;
  stroke-linecap: round;
  fill: none;
}
.st8,
.st9 {
  fill: #fff;
}
.st9 {
  fill: none;
}

#cloud1 {
  -webkit-animation: cloud003 15s linear infinite;
          animation: cloud003 15s linear infinite;
}

#cloud2 {
  -webkit-animation: cloud002 25s linear infinite;
          animation: cloud002 25s linear infinite;
}

#cloud3 {
  -webkit-animation: cloud003 20s linear infinite;
          animation: cloud003 20s linear infinite;
}

#cloud4 {
  -webkit-animation: float 4s linear infinite;
          animation: float 4s linear infinite;
}

#cloud5 {
  -webkit-animation: float 8s linear infinite;
          animation: float 8s linear infinite;
}

#cloud7 {
  -webkit-animation: float 5s linear infinite;
          animation: float 5s linear infinite;
}

#tracks {
  -webkit-animation: slide 650ms linear infinite;
          animation: slide 650ms linear infinite;
}

#bumps {
  -webkit-animation: land 10000ms linear infinite;
          animation: land 10000ms linear infinite;
}

@-webkit-keyframes jig {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes jig {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0px);
  }
}

#car-layers {
  -webkit-animation: jig 0.35s linear infinite;
          animation: jig 0.35s linear infinite;
}

@-webkit-keyframes land {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(1000px);
  }
}

@keyframes land {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(1000px);
  }
}

@-webkit-keyframes slide {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(100px);
  }
}

@keyframes slide {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(100px);
  }
}

/* @keyframes cloudFloat {
  0% { transform: translateX(0) translateY(3px); }
  100% { transform: translateX(1000px) translateY(0); }
} */

@-webkit-keyframes cloud001 {
  0% {
    transform: translateX(-1000px) translateY(3px);
  }
  100% {
    transform: translateX(1000px) translateY(0);
  }
}

@keyframes cloud001 {
  0% {
    transform: translateX(-1000px) translateY(3px);
  }
  100% {
    transform: translateX(1000px) translateY(0);
  }
}

@-webkit-keyframes cloud002 {
  0% {
    transform: translateX(-1000px) translateY(3px);
  }
  100% {
    transform: translateX(1000px) translateY(0);
  }
}

@keyframes cloud002 {
  0% {
    transform: translateX(-1000px) translateY(3px);
  }
  100% {
    transform: translateX(1000px) translateY(0);
  }
}

@-webkit-keyframes cloud003 {
  0% {
    transform: translateX(-1000px) translateY(3px);
  }
  100% {
    transform: translateX(1000px) translateY(0);
  }
}

@keyframes cloud003 {
  0% {
    transform: translateX(-1000px) translateY(3px);
  }
  100% {
    transform: translateX(1000px) translateY(0);
  }
}

@-webkit-keyframes float {
  0% {
    transform: translateY(0px) translateX(0);
  }
  50% {
    transform: translateY(8px) translateX(5px);
  }
  100% {
    transform: translateY(0px) translateX(0);
  }
}

@keyframes float {
  0% {
    transform: translateY(0px) translateX(0);
  }
  50% {
    transform: translateY(8px) translateX(5px);
  }
  100% {
    transform: translateY(0px) translateX(0);
  }
}

#bracefront,
#braceback {
  -webkit-animation: braces 1s linear infinite;
          animation: braces 1s linear infinite;
}

@-webkit-keyframes braces {
  0% {
    transform: translateX(-2px);
  }
  25% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(-2px);
  }
  75% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(-2px);
  }
}

@keyframes braces {
  0% {
    transform: translateX(-2px);
  }
  25% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(-2px);
  }
  75% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(-2px);
  }
}
.btn-go-home {
  position: relative;
  z-index: 200;
  margin: 15px auto;
  width: 150px;
  padding: 10px 15px;
  border: 1px solid #3967ff;
  border-radius: 100px;
  font-weight: 400;
  display: block;
  color: rgb(25, 26, 27);
  text-align: center;
  text-decoration: none;
  letter-spacing: 2px;
  font-size: 11px;
  transition: all 0.3s ease-in;
}

.btn-go-home:hover {
  background-color: #39a6ff;
  color: rgb(103, 191, 241);
  transform: scale(1.05);
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
}

.flex-row {
  display: flex;
  justify-content: space-between;
}
.ant-card-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  zoom: 1;
}
.new-form-item {
  padding-right: 1em;
  width: 70%;
}
.form-row-item {
  padding-right: 1em;
}
.base-form {
  margin-left: 10vw;
  margin-top: 3vw;
  width: 66%;
}
.form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ant-radio-button-wrapper-checked {
  background: "purple";
  background-color: "purple";
}
.debt-collector-form-container {
  margin: 3vh 10vw 3vh 10vw;
  width: 80%;
}
.form-item-block {
  display: flex;
  justify-content: space-between;
  grid-gap: 2rem;
  gap: 2rem;
}
.form-item-block > .input-block {
  width: 48%;
  flex: 1 1;
}
.ant-form-item-children > input,
.ant-select-selection--single,
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child,
.input-height,
.ant-input-number-input {
  height: 35px;
}
.submission-result {
  margin: 10%;
}
.submission-result > div > div {
  width: 50%;
  padding-top: 10%;
}
.penalty-payment-details {
  display: flex;
  flex-direction: row;
  grid-gap: 2rem;
  gap: 2rem;
}

