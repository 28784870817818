.header-wrapper {
  border-top: 1px solid lightgrey;
  background: rgb(255, 255, 255);
  display: flex;
}
.headers {
  height: 17px;
  font: 400 11px system-ui;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: #435a6f;
  padding-top: 16px;
  border-bottom: 1px solid lightgrey;
  background: rgb(255, 255, 255);
  height: 55px;
  text-align: center;
  border-right: 1px solid lightgrey;
}

.proj-header {
  background: rgb(255, 255, 255);
  padding: 0;
  height: 55px;
  text-align: center;
  display: flex;
  align-items: center;
}

.project-name {
  text-align: center;
  height: 20px;
  font: 400 14px system-ui;
  line-height: 1.25;
  letter-spacing: -0.05px;
  color: var(--dark-slate-blue);
  margin: 10px 5px 0 0;
}
.proj-info {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: rgb(255, 255, 255);
  padding: 10px;
}
.custom-project-filter {
  margin-top: 2vw;
  margin-left: auto;
  margin-right: 2vw;
  width: 25%;
  position: relative;
}
p {
  font: 400 11px system-ui;
  font-size: 13px;
  height: 20px;
  margin: 1em 1em 0 0;
}
.ant-card-meta-avatar {
  margin-top: 2.5em;
}
.ant-card-meta-title {
  font-size: 13px;
}
.card {
  border: 1px solid lightgrey;
  border-radius: 4px;
  flex-basis: 0em;
  padding-bottom: 2.2em;
  margin-bottom: 1.5em;
}
.ant-card {
  width: 100%;
  font-variant: "system-ui";
  margin-bottom: 1em;
}
.ant-comment {
  margin-left: 10px;
  font: 400 13px system-ui;
}
.ant-comment-avatar {
  width: 50px;
}
.ant-avatar-image img {
  margin: 0;
}
.reply {
  /* position: absolute; */
  /* bottom: 0; */
  margin-top: auto;
  align-self: center;
  border-top: 1px solid rgb(230, 230, 230);
  border-bottom: 1px solid lightgrey;
  width: 100%;
  background: rgb(255, 255, 255);

  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.file-upload {
  display: flex;
  padding-top: 15px;
  width: 10%;
}
.file-upload svg {
  width: 50px;
  height: 30px;
  text-align: left;
}
textarea {
  border: none;
  resize: none;
  box-sizing: border-box;
  width: 90%;
  height: 100%;
  font-family: intercom-font, "Helvetica Neue", "Apple Color Emoji", Helvetica,
    Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.33;
  background-color: rgb(255, 255, 255);
  white-space: pre-wrap;
  overflow-wrap: break-word;
  padding: 18px 100px 18px 29px;

  height: 55px;
}
textarea,
input {
  cursor: text;
}
text {
  font: 400 13px system-ui;
}
.ant-comment {
  align-self: flex-start;
}
.concierge-comment {
  align-self: flex-end;
  background-color: rgb(255, 255, 255);
}
.ant-comment-content-detail p {
  background-color: #408ef3;
  border: 1px solid lightgrey;
  border-radius: 10px;
  min-height: 3em;
  height: fit-content;
  width: 25vw;
  font: 400 13px system-ui;
  padding: 12px;
}
.ant-comment-content-author-name {
  font: 400 14px system-ui;
}
.ant-tabs-tab {
  font: 400 12px system-ui;
  padding-top: 20px !important;
}
.projects-container {
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  display: flex;
  height: 92.5vh;
  overflow-y: auto;
  flex-direction: column;
  background: rgb(255, 255, 255);
}
.chat-container {
  background: rgb(255, 255, 255);
  padding: 0;
  height: 92.5vh;
}

.ant-tabs {
  overflow-y: auto;
}

.ant-tabs-ink-bar {
  display: none;
}
.chat {
  display: flex;
  max-height: 83vh;
  flex-direction: column;
  background: rgb(255, 255, 255);
  overflow-y: auto;
  margin: 0;
}
.section-background {
  background: rgb(255, 255, 255);
  height: 92.5vh;
  display: flex;
  flex-direction: column;
  padding-left: 0%;
}

.chatroom-content {
  display: flex;
  overflow-y: hidden;
  background: rgb(255, 255, 255);
}
.active {
  background-color: rgb(209, 238, 250);
}
.ant-table-no-data {
  min-height: 92vh;
}
.placeholder-card {
  margin-top: 2.5em;
  height: 100%;
}
.placeholder-card-spacer {
  margin: 2.5em;
}
.placeholder-table-card {
  margin: 0.6em;
}
.ant-table-wrapper {
  overflow-y: auto;
}
.bu-icon {
  margin-top: 10px;
  height: 6vh;
}
.column-flex {
  display: flex;
  flex-direction: column;
}
.project-card {
  width: 96%;
}
.project-card-title {
  height: 64px;
  padding-right: 15px;
}
.project-header-card {
  margin: 2em 1em 0 2em;
  border: none;
}
.project-status-card {
  margin: 0 1em 1em 2em;
  border: none;
}
.project-team-card {
  display: flex;
  padding-right: 5vw;
}
.avatar {
  height: 50px;
  padding-right: 5;
  padding-top: 4;
}
.team-member-role {
  color: rgba(0, 0, 0, 0.45);
}
.pagination-container {
  text-align: center;
  margin-top: 15px;
}
.filter-holder {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.horizontal-padding-25 {
  padding-left: 25px;
  padding-right: 25px;
}
.filter {
  padding-right: 15px;
  padding-bottom: 5px;
}
.search-filter {
  display: flex;
  margin-left: auto;
}
.ant-spin-nested-loading {
  width: 100%;
}
.change-dates-headings {
  margin: 0.5rem 0;
}
.change-dates-date-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0.5rem;
}
.change-dates-date-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}
